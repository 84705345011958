import React from 'react';
import Layout from '../../../../components/Layout';
import Seo from '../../../../components/SEO';
import SectionIntro from '../../../../components/SectionIntro';
import {useStaticQuery, graphql} from 'gatsby';
import {getImage} from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';
import {convertToBgImage} from 'gbimage-bridge';
import BackGlossary from '../../../../components/BackGlossary';
import BannerSimple from '../../../../components/BannerSimple';

const GlossaryPageContent = () => {
  return (
    <div className="md:px-5 max-w-[1285px]">
      <div className="mb-8 text-xl font-bold text-left md:text-2xl">
        The Essential Role of DevSecOps in Enterprise Companies
      </div>
      <p className="mb-12 text-lg text-left">
        DevSecOps is a term used to describe the integration of development, security, and operations teams to ensure
        that software is secure, reliable, and efficient throughout its entire lifecycle. By working together, these
        teams can ensure that software is developed and deployed quickly, while also meeting rigorous security and
        compliance standards. On this page, we'll explore the history of DevSecOps, its differences from DevOps, and why
        it's critical for enterprise companies in 2023 and beyond.
      </p>

      <p className="mb-6 text-lg font-bold text-left">What is DevSecOps?</p>
      <p className="mb-12 text-lg text-left">
        DevSecOps is a philosophy that emphasizes the importance of security throughout the software development
        process. Unlike traditional software development approaches that treat security as an afterthought, DevSecOps
        integrates security into every stage of the software development lifecycle. This approach helps to minimize the
        risk of security vulnerabilities and ensures that software is secure, reliable, and compliant with industry
        standards.
      </p>

      <p className="mb-6 text-lg font-bold text-left">DevSecOps vs DevOps</p>
      <p className="mb-12 text-lg text-left">
        DevOps is a development philosophy that emphasizes collaboration and automation between development and
        operations teams. While DevSecOps shares many of the same principles as DevOps, it places a greater emphasis on
        security and compliance. By integrating security into the development process, DevSecOps helps to reduce the
        risk of security breaches and ensure that software is compliant with industry standards.
      </p>

      <p className="mb-6 text-lg font-bold text-left">Best Practices for DevSecOps</p>
      <p className="mb-6 text-lg text-left">
        To implement DevSecOps successfully, organizations must adopt several best practices, including:
      </p>

      <ul className="mb-12 ml-12 text-lg list-disc ">
        <li className="mb-5 font-bold">Integrate security into the development process</li>
        <li className="mb-5 font-bold">
          Automate security testing and compliance checks Implement access management and identity governance policies
        </li>
        <li className="mb-5 font-bold">
          Use DevSecOps tools and solutions to streamline development, security, and operations workflows Use cloud
          security solutions to protect against threats and vulnerabilities
        </li>
        <li className="mb-5 font-bold">
          Push approvals to the owners of the resources to ensure the correct context is apparent
        </li>
      </ul>

      <p className="mb-6 text-lg font-bold text-left">DevSecOps Pipeline</p>
      <p className="mb-12 text-lg text-left">
        A DevSecOps pipeline is a series of automated steps that software goes through as it moves from development to
        production. This pipeline includes automated security testing, compliance checks, and vulnerability scans to
        ensure that the software is secure and compliant with industry standards. By using a DevSecOps pipeline,
        organizations can ensure that software is developed quickly while minimizing the risk of security
        vulnerabilities and compliance issues.
      </p>

      <p className="mb-6 text-lg font-bold text-left">DevSecOps Methodology</p>
      <p className="mb-12 text-lg text-left">
        The DevSecOps methodology is an approach to software development that emphasizes collaboration, automation, and
        security. By integrating security into every stage of the software development lifecycle, organizations can
        ensure that their software is secure, reliable, and compliant with industry standards. The DevSecOps methodology
        also involves the use of DevSecOps tools and solutions to streamline workflows and improve efficiency.
      </p>

      <p className="mb-6 text-lg font-bold text-left">How to Implement DevSecOps</p>
      <p className="mb-6 text-lg text-left">
        To implement DevSecOps successfully, organizations must take several steps, including:
      </p>

      <ul className="mb-12 ml-12 text-lg list-disc">
        <li className="mb-5 font-bold">Adopting a DevSecOps mindset and culture </li>
        <li className="mb-5 font-bold">Introducing DevSecOps tools and solutions</li>
        <li className="mb-5 font-bold">Implementing access management and identity governance policies</li>
        <li className="mb-5 font-bold">Automating security testing and compliance checks</li>
        <li className="mb-5 font-bold">
          Using cloud security solutions to protect against threats and vulnerabilities
        </li>
      </ul>

      <p className="mb-6 text-lg font-bold text-left">DevSecOps Security Solutions</p>
      <p className="mb-12 text-lg text-left">
        DevSecOps security solutions are software tools that help organizations implement DevSecOps principles and best
        practices. These solutions include security testing tools, compliance monitoring tools, access management
        solutions, and cloud security solutions. But because the landscape of SaaS and Cloud services and systems are
        growing for organizations, it can seem impossible to ensure that their software is secure, reliable, and
        compliant with industry standards.
      </p>

      <p className="mb-6 text-lg font-bold text-left">Trustle: Your DevSecOps Partner</p>
      <p className="text-lg text-left">
        At Trustle, we understand the importance of DevSecOps in today's fast-paced digital landscape. That's why we
        offer DevSecOps tools and solutions designed to help organizations improve their software security and
        compliance. Whether you're looking to implement DevSecOps for the first time or improve your existing DevSecOps
        and security practices, we can help.
      </p>
    </div>
  );
};

const DevsecopsDevelopmentSecurityAndOperations = () => {
  const dataImages = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: {eq: "bg/rebrand/bg-main-top.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
        }
      }
    }
  `);
  const {placeholderImage} = dataImages;
  const image = getImage(placeholderImage);
  const bgImage = convertToBgImage(image);
  const title = `DevSecOps`;
  const description = `Development Security and Operations`;
  return (
    <Layout seo={<Seo title={`Glossary: ${title} | Trustle`} description={description} />}>
      <BackgroundImage
        Tag="section"
        // Spread bgImage into BackgroundImage:
        {...bgImage}
        preserveStackingContext
        className="bg-cover"
      >
        <section className="">
          <div className="flex flex-col pt-16 mx-5 md:mx-8 lg:px-4 lg:max-w-[1920px] lg:mx-auto ">
            <SectionIntro isPageHeader hero="Glossary" title={title} description={`(${description})`} />
          </div>
        </section>

        <section className="pb-16 ">
          <div className="flex flex-col py-16 mx-5 md:mx-8 lg:px-4 lg:max-w-[1920px] lg:mx-auto">
            <div className=" max-w-[1360px] 3xl:max-w-[1420px] mx-auto text-blue">
              <div className="py-16">
                <BackGlossary />
              </div>
              <GlossaryPageContent />
            </div>
          </div>
          <BannerSimple
            title="Curious how Trustle works?"
            description={'Get an inside look at Trustle. Schedule a demo with us today.'}
          />
        </section>
      </BackgroundImage>
    </Layout>
  );
};

export default DevsecopsDevelopmentSecurityAndOperations;
